<template>
  <page-list
    :list="list"
    :columns="columns"
    :group-actions="groupActions"
    :single-actions="singleActions" />
</template>

<script>
import * as R from 'ramda'
import WindowsMixin from '@/mixins/windows'
import ListMixin from '@/mixins/list'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'

export default {
  name: 'CloudProductTypeList',
  mixins: [WindowsMixin, ListMixin, ColumnsMixin, SingleActionsMixin],
  props: {
    id: String,
    getParams: {
      type: [Function, Object],
    },
    cloudEnv: String,
  },
  data () {
    return {
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'cloud_product_types',
        getParams: this.getParam,
        filterOptions: {
          name: {
            field: 'name',
            label: '类型名称',
          },
          // product_count: getFilter({
          //   field: 'product_count',
          //   title: '产品数量',
          // }),
          // created_at: getCreatedAtFilter(),
        },
        hiddenColumns: [],
      }),
      exportDataOptions: {
        items: [
          { label: 'ID', key: 'id' },
          { label: '类型名称', key: 'name' },
          { label: '产品数量', key: 'product_count' },
          { label: '创建时间', key: 'created_at' },
        ],
      },
      groupActions: [
        {
          label: this.$t('compute.perform_create'),
          permission: 'cloud_product_types_create',
          action: () => {
            this.createDialog('CreateOrUpdateCloudProductTypesDialog', {
              title: this.$t('compute.perform_create'),
              onManager: this.onManager,
              row: {},
            })
          },
          meta: () => {
            const meta = {
              buttonType: 'primary',
              validate: true,
            }
            return meta
          },
        },
        {
          label: this.$t('common.batchAction'),
          actions: () => {
            return [
              {
                label: this.$t('compute.perform_delete'),
                permission: 'cloud_product_types_delete',
                action: () => {
                  this.createDialog('DeleteResDialog', {
                    vm: this,
                    data: this.list.selectedItems,
                    columns: this.columns,
                    title: this.$t('compute.text_1053'),
                    name: this.$t('dictionary.sku'),
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  return this.$getDeleteResult(this.list.selectedItems)
                },
              },
            ]
          },
        },
      ],
    }
  },
  watch: {
    cloudEnv (val) {
      this.$nextTick(() => {
        this.list.fetchData(0)
      })
    },
  },
  created () {
    this.initSidePageTab('sku-detail')
    this.list.fetchData()
  },
  methods: {
    getParam () {
      const ret = {
        details: true,
        with_meta: true,
        ...(R.is(Function, this.getParams) ? this.getParams() : this.getParams),
      }
      if (this.cloudEnv) ret.cloud_env = this.cloudEnv
      return ret
    },
    handleOpenSidepage (row) {
      this.sidePageTriggerHandle(this, 'SkuSidePage', {
        id: row.id,
        resource: 'serverproducttypes',
        getParams: this.getParam,
        cloudEnv: this.cloudEnv,
      }, {
        list: this.list,
      })
    },
  },
}
</script>

// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
// import { findPlatform } from '@/utils/common/hypervisor'

export default {
  computed: {
    singleActions () {
      const singleActions = [
        {
          label: i18n.t('common_428'),
          permission: 'cloud_products_update',
          action: row => {
            // 跳转配置
            if (row.billing_type === 'prepaid') {
              this.createDialog('CloudProductPrepaidConfigDialog', {
                onManager: this.onManager,
                refresh: this.refresh,
                row,
              })
            } else {
              this.createDialog('CloudProductPostpaidConfigDialog', {
                onManager: this.onManager,
                refresh: this.refresh,
                row,
              })
            }
          },
        },
        {
          label: i18n.t('system.text_153'),
          actions: row => {
            return [
              // {
              //   label: i18n.t('compute.text_749'),
              //   permission: 'cloud_products_update',
              //   action: row => {
              //     // 跳转详情
              //     this.createDialog('CreateOrUpdateCloudProductsDialog', {
              //       title: i18n.t('compute.text_749'),
              //       onManager: this.onManager,
              //       row,
              //       refresh: this.refresh,
              //     })
              //   },
              // },
              {
                label: '启用',
                permission: 'cloud_products_perform_enable',
                action: row => {
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'enable',
                    },
                  })
                },
                meta: obj => {
                  if (obj.enabled) {
                    return {
                      validate: false,
                      tooltip: '',
                    }
                  } else {
                    return {
                      validate: true,
                      tooltip: '',
                    }
                  }
                },
              },
              {
                label: '禁用',
                permission: 'cloud_products_perform_disable',
                action: row => {
                  // 提示
                  this.$confirm({
                    content: '确认禁用?',
                    okText: '确认',
                    onOk: () => {
                      // 用户确认取消
                      this.onManager('performAction', {
                        id: row.id,
                        managerArgs: {
                          action: 'disable',
                        },
                      })
                    },
                    onCancel: () => {
                    },
                  })
                },
                meta: obj => {
                  if (obj.enabled) {
                    return {
                      validate: true,
                      tooltip: '',
                    }
                  } else {
                    return {
                      validate: false,
                      tooltip: '',
                    }
                  }
                },
              },
              {
                label: i18n.t('compute.perform_delete'),
                permission: 'cloud_products_delete',
                action: (obj) => {
                  this.createDialog('DeleteResDialog', {
                    vm: this,
                    data: [obj],
                    columns: this.columns,
                    title: i18n.t('compute.perform_delete'),
                    name: this.$t('dictionary.cloud_product'),
                    onManager: this.onManager,
                  })
                },
                meta: (obj) => {
                  return this.$getDeleteResult(obj)
                },
              },
            ]
          },
        },
      ]
      return singleActions
    },
  },
}

// import { chargeTypeColumn } from '../utils'
import {
  getTimeTableColumn,
  getEnabledTableColumn,
} from '@/utils/common/tableColumn'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'

export default {
  computed: {
    columns () {
      const columns = [
        {
          field: 'name',
          title: '名称',
        },
        {
          field: 'type_name',
          title: '类型',
        },
        {
          field: 'billing_type',
          title: '计费方式',
          slots: {
            default: ({ row }) => {
              return [<span>{ row.billing_type === 'prepaid' ? '包年包月套餐' : '按量计费' } </span>]
            },
          },
        },
        getEnabledTableColumn(),
        getTimeTableColumn({
          field: 'enable_time',
          title: '启用时间',
        }),
      ]
      return columns
    },
  },
}

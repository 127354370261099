// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
// import { findPlatform } from '@/utils/common/hypervisor'

export default {
  computed: {
    singleActions () {
      const singleActions = [
        {
          label: i18n.t('compute.text_749'),
          permission: 'cloud_product_types_update',
          action: row => {
            // 跳转详情
            this.createDialog('CreateOrUpdateCloudProductTypesDialog', {
              title: i18n.t('compute.text_749'),
              onManager: this.onManager,
              row,
            })
          },
        },
        {
          label: i18n.t('compute.perform_delete'),
          permission: 'cloud_product_types_delete',
          action: (obj) => {
            this.createDialog('DeleteResDialog', {
              vm: this,
              data: [obj],
              columns: this.columns,
              title: i18n.t('compute.perform_delete'),
              name: this.$t('dictionary.cloud_product_type'),
              onManager: this.onManager,
            })
          },
          meta: (obj) => {
            return this.$getDeleteResult(obj)
          },
        },
      ]
      return singleActions
    },
  },
}

<template>
  <page-list
    :list="list"
    :columns="columns"
    :group-actions="groupActions"
    :single-actions="singleActions" />
</template>

<script>
import * as R from 'ramda'
import WindowsMixin from '@/mixins/windows'
import ListMixin from '@/mixins/list'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'

export default {
  name: 'CloudProductList',
  mixins: [WindowsMixin, ListMixin, ColumnsMixin, SingleActionsMixin],
  props: {
    id: String,
    getParams: {
      type: [Function, Object],
    },
    cloudEnv: String,
  },
  data () {
    return {
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'cloud_products',
        getParams: this.getParam,
        filterOptions: {
          name: {
            field: 'name',
            label: '名称',
          },
          type_id: {
            label: '类型',
            dropdown: true,
            items: [],
          },
          // product_count: getFilter({
          //   field: 'product_count',
          //   title: '产品数量',
          // }),
          // created_at: getCreatedAtFilter(),
        },
        hiddenColumns: [],
      }),
      exportDataOptions: {
        items: [
          { label: 'ID', key: 'id' },
          { label: '名称', key: 'name' },
          { label: '类型', key: 'cloud_product_type' },
          { label: '计费方式', key: 'billing_type' },
          { label: '状态', key: 'enabled' },
          { label: '启用时间', key: 'created_at' },
        ],
      },
      groupActions: [
        {
          label: this.$t('compute.perform_create'),
          permission: 'cloud_products_create',
          action: () => {
            this.createDialog('CreateOrUpdateCloudProductsDialog', {
              title: this.$t('compute.perform_create'),
              onManager: this.onManager,
              row: {},
              refresh: this.refresh,
            })
          },
          meta: () => {
            const meta = {
              buttonType: 'primary',
              validate: true,
            }
            return meta
          },
        },
        // {
        //   label: this.$t('common.batchAction'),
        //   actions: () => {
        //     return [
        //       {
        //         label: this.$t('compute.perform_delete'),
        //         permission: 'cloud_products_delete',
        //         action: (obj) => {
        //           this.createDialog('DeleteResDialog', {
        //             vm: this,
        //             data: this.list.selectedItems,
        //             columns: this.columns,
        //             title: this.$t('compute.perform_delete'),
        //             name: this.$t('dictionary.cloud_product'),
        //             onManager: this.onManager,
        //           })
        //         },
        //         meta: () => {
        //           return this.$getDeleteResult(this.list.selectedItems)
        //         },
        //       },
        //     ]
        //   },
        // },
      ],
    }
  },
  watch: {
    cloudEnv (val) {
      this.$nextTick(() => {
        this.list.fetchData(0)
      })
    },
  },
  created () {
    this.list.fetchData()
    this.getCloudProductTypes()
  },
  methods: {
    getParam () {
      const ret = {
        details: true,
        with_meta: true,
        ...(R.is(Function, this.getParams) ? this.getParams() : this.getParams),
      }
      if (this.cloudEnv) ret.cloud_env = this.cloudEnv
      return ret
    },
    // 获取云产品类型
    getCloudProductTypes () {
      this.$http.get('/v2/cloud_product_types').then((result) => {
        const list = result?.data.data || []
        list.forEach(item => {
          item.label = item.name
          item.key = item.type_id
        })
        if (!this.list) return
        this.list.filterOptions.type_id.items = list
      })
    },
  },
}
</script>

// import { chargeTypeColumn } from '../utils'
import {
  getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'

export default {
  computed: {
    columns () {
      const columns = [
        {
          field: 'name',
          title: '类型名称',
        },
        {
          field: 'product_count',
          title: '产品数量',
        },
        getTimeTableColumn(),
      ]
      return columns
    },
  },
}

// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import i18n from '@/locales'
// import { findPlatform } from '@/utils/common/hypervisor'

export default {
  computed: {
    singleActions () {
      const singleActions = [
        {
          label: '定价',
          permission: 'cloud_product_prices_update',
          action: row => {
            // 跳转配置
            if (row.billing_type === 'prepaid') {
              this.createDialog('CloudProductPricePrepaidConfigDialog', {
                onManager: this.onManager,
                row,
              })
            } else {
              this.createDialog('CloudProductPricePostpaidConfigDialog', {
                onManager: this.onManager,
                row,
              })
            }
          },
        },
        {
          label: i18n.t('system.text_153'),
          actions: row => {
            return [
              {
                label: '上架',
                permission: 'cloud_product_prices_perform_enable',
                action: row => {
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'enable',
                    },
                  })
                },
                meta: obj => {
                  if (!obj.product_enable) {
                    return {
                      validate: false,
                      tooltip: '',
                    }
                  }
                  if (obj.enabled) {
                    return {
                      validate: false,
                      tooltip: '',
                    }
                  } else {
                    return {
                      validate: true,
                      tooltip: '',
                    }
                  }
                },
              },
              {
                label: '下架',
                permission: 'cloud_product_prices_perform_disable',
                action: row => {
                  // 提示
                  // this.$confirm({
                  //   content: '禁用该计算资源后，该资源在租户端及超管端将不可新建，请谨慎操作',
                  //   okText: '确认禁用',
                  //   onOk: () => {
                  // 用户确认取消
                  this.onManager('performAction', {
                    id: row.id,
                    managerArgs: {
                      action: 'disable',
                    },
                  })
                  //   },
                  //   onCancel: () => {
                  //   },
                  // })
                },
                meta: obj => {
                  if (!obj.product_enable) {
                    return {
                      validate: false,
                      tooltip: '',
                    }
                  }
                  if (obj.enabled) {
                    return {
                      validate: true,
                      tooltip: '',
                    }
                  } else {
                    return {
                      validate: false,
                      tooltip: '',
                    }
                  }
                },
              },
              // {
              //   label: i18n.t('compute.perform_delete'),
              //   permission: 'cloud_products_delete',
              //   action: (obj) => {
              //     this.createDialog('DeleteResDialog', {
              //       vm: this,
              //       data: [obj],
              //       columns: this.columns,
              //       title: i18n.t('compute.perform_delete'),
              //       name: this.$t('dictionary.cloud_product_price'),
              //       onManager: this.onManager,
              //     })
              //   },
              //   meta: (obj) => {
              //     return this.$getDeleteResult(obj)
              //   },
              // },
            ]
          },
        },
      ]
      return singleActions
    },
  },
}
